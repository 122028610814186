import { render, staticRenderFns } from "./ArticlesItemImage.vue?vue&type=template&id=6448fa52&"
import script from "./ArticlesItemImage.vue?vue&type=script&lang=js&"
export * from "./ArticlesItemImage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports