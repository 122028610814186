<template>
  <div class="d-flex flex-column flex-grow-1 mr-2 mb-2 rounded">
    <BaseToolBar title="Articles" subtitle="Liste des articles disponibles">
      <BaseButton
        class="btn btn-primary ml-3"
        @click="storeArticle"
        :loading="loading"
        icon="plus"
        text="Ajouter un article"
      ></BaseButton>
    </BaseToolBar>
    <div class="w-100">
      <div v-if="newArticles.length > 0" class="flex-grow-1 d-flex flex-column mb-3">
        <div v-for="article in newArticles" :key="'actives_' + article.id">
          <articles-item :article="article"></articles-item>
        </div>
      </div>
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="article in oldArticles" :key="'actives_' + article.id">
          <articles-item :article="article"></articles-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";

import BaseButton from "@/components/bases/Button.vue";
import ArticlesItem from "@/components/materiels/ArticlesItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

export default {
  components: {
    ArticlesItem,
    BaseToolBar,
    BaseButton,
  },
  data() {
    return {
      loading: false,
      feedback: {},
    };
  },
  methods: {
    ...mapActions({
      _storeArticle: "materiel/createArticle",
    }),
    storeArticle: function () {
      this.feedback = {};
      this.loading = true;
      this._storeArticle(this.newArticle)
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {},
  computed: {
    ...mapMultiRowFields("materiel", ["articles"]),
    newArticles() {
      return this.articles.filter((art) => art.new == true);
    },
    oldArticles() {
      return this.articles.filter((art) => art.new == false);
    },
  },
};
</script>
<style lang="css"></style>
