<template>
  <container-list-extend title="Article">
    <template v-slot:title>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1" v-if="!article.new">
        {{ article.description }}
      </div>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1 text-primary" v-else>
        Nouvel article à saisir
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small></small>
      </div>
    </template>
    <template v-slot:form>
      <base-form
        @click="updateArticle(article)"
        deleteBtn
        @delete="deleteArticle(article)"
        :statut="statut"
        :loading="loading"
      >
        <div class="d-flex flex-column flex-md-row">
          <articles-item-image
            v-if="article.id"
            class="p-0 p-md-2 ml-md-3 mr-md-5"
            v-model="article.image"
            :url="'/materiels/article/' + article.id + '/avatar'"
            propSize="8rem"
            :id="article.id"
          ></articles-item-image>
          <div class="w-100">
            <base-form-row row>
              <base-input v-model="article.description" inputText="Description"></base-input>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Fournisseur"
                v-model.number="article.fournisseur_id"
                :options="fournisseurs"
              ></base-select>
            </base-form-row>
            <base-form-row row>
              <base-select
                inputText="Type"
                v-model.number="article.type_id"
                :options="data.materielTypes"
              ></base-select>
            </base-form-row>
            <base-form-row row>
              <base-input v-model="article.specs" inputText="Spécifications"></base-input>
            </base-form-row>
            <base-form-row>
              <InputNumberButton
                v-model.number="article.stock_securite"
                text="Stock de sécurité"
                :max="100"
                :min="0"
                :errors="feedback.stock_securite"
              ></InputNumberButton>
              <InputNumberButton
                v-model.number="article.prix_unitaire_ht"
                text="Prix unitaire (HT)"
                :max="1000"
                :min="0"
                :errors="feedback.prix_unitaire_ht"
                :step="0.01"
              ></InputNumberButton>
            </base-form-row>
          </div>
        </div>
        <uploader
          v-if="article.id"
          :id="'document_articles' + article.id"
          url="/upload/document"
          title="Déposer vos fichiers"
          :file_system="'MATERIEL/articles'"
          :file_field="'article'"
          :file_key="article.id"
        ></uploader>
      </base-form>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";

//import BaseIcon from "@/components/bases/Icon.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import InputNumberButton from "@/components/bases/InputNumberButton.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import ArticlesItemImage from "@/components/materiels/ArticlesItemImage.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  name: "ArticlesItem",
  components: {
    //BaseIcon,
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInput,
    InputNumberButton,
    BaseSelect,
    ArticlesItemImage,
    Uploader,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
    };
  },
  props: ["article"],
  computed: {
    ...mapGetters("materiel", ["fournisseurs"]),
    ...mapGetters("materiel", ["data"]),
  },
  methods: {
    ...mapActions({
      _updateArticle: "materiel/updateArticle",
      _deleteArticle: "materiel/deleteArticle",
    }),
    updateArticle: function (article) {
      this.feedback = {};
      this.loading = true;
      this._updateArticle(article)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteArticle: function (article) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteArticle(article)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
};
</script>
<style lang="css"></style>
